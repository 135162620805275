import React, { useEffect, useRef } from 'react'
import Player from '@vimeo/player'
import Modal from './Modal'

const VimeoPlayer = ({ isOpen, project, onClose }) => {

	const iframeContainerEl = useRef(null)
	const iframeEl = useRef(null)
	const player = useRef(null)

	const handleModalClose = () => {
		onClose()
	}

	useEffect(() => {
		if (project === null || project.vimeo_id === null || project.vimeo_id.text === '') {
			return
		}

		const options = {
			id: project.vimeo_id.text,
			responsive: true,
			autoplay: true,
		}

		player.current = new Player(iframeContainerEl.current, options)
		player.current.ready().then(() => {
			iframeEl.current = iframeContainerEl.current.querySelector('iframe')
		})

		return () => {
			player.current.destroy()
		}
	}, [project])

	/**
 	*	Handle resize
	*/

	useEffect(() => {
		if (player.current === null || project === null) {
			return
		}

		const handleResize = () => {
			if (iframeEl.current === null) {
				return
			}
			const videoHeightInPx = iframeEl.current.getBoundingClientRect().height
			let videoTopInPx = (window.innerHeight - videoHeightInPx) * 0.5
			iframeContainerEl.current.style.marginTop = videoTopInPx + 'px'
		}

		player.current.ready().then(() => {
			handleResize()
		})
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [project])

	return (
		<div className="VimeoPlayer">
			<Modal
				isOpen={project !== null ? true : false}
				onClose={handleModalClose}
			>
				<div className="VimeoPlayer__Container">
					{project &&
						<p className="VimeoPlayer__Client">
							{project.client.text}
						</p>
					}
					<div
						className="VimeoPlayer__IframeContainer"
						ref={iframeContainerEl}
					></div>
					{project &&
						<div className="VimeoPlayer__InfosBottom">
							<p className="VimeoPlayer__Title">
								{project.title.text}
							</p>
							<p className="VimeoPlayer__DirectorAndProductionCompany">
								Directed by {project.director.text} – Production company {project.production_company.text}
							</p>
						</div>
					}
				</div>
			</Modal>
		</div>
	)
}

export default VimeoPlayer