import React, { useEffect } from 'react'

const Modal = ({ children, isOpen, onClose }) => {

	const handleClickOnClose = () => {
		onClose()
	}

	useEffect(() => {
		if (window.location.pathname !== '/') {
			document.documentElement.classList.toggle('noScroll', isOpen)
			document.body.classList.toggle('noScroll', isOpen)
		}
	}, [isOpen])

	return (
		<div className={`Modal ${isOpen ? 'isOpen' : ''}`}>
			<div
				className="Modal__Close"
				onClick={handleClickOnClose}
			>
				Close
			</div>
			{children}
		</div>
	)
}

export default Modal